<template>
  <section
    class="onboarding__content onboarding__content--purchase"
    ref="onboardingContent"
  >
    <div>
      <div class="chameleon-purchase-title__wrapper">
        <div class="chameleon-purchase-title">
          Online security at the best price
        </div>
      </div>
      <span class="purchase__payment-images">
        <img
          v-for="(image, index) in paymentImages"
          :key="index"
          :src="image.src"
          :alt="image.name"
          class="purchase__payment-images-item"
        />
      </span>
      <div class="purchase__header">
        <div class="purchase__info">
          <div
            v-if="currentTariff?.discount"
            class="purchase__info-item tariffsDefault_discount-block"
          >
            <div class="purchase__info-label text text-lg tariffsDefault_title">
              {{ currentTariff?.title }}
            </div>
            <div class="purchase__info-data text text-lg">
              <div>
                <span class="purchase__info-label_num tariffsDefault_first">
                  ${{ currentTariff?.oldPrice }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="currentTariff?.discount"
            class="purchase__info-item tariffsDefault_discount-block"
          >
            <div class="purchase__info-label text text-lg">
              {{ $t('web.prices_discount') }}
            </div>
            <div class="purchase__info-data text text-lg">
              <div>
                <span class="purchase__info-label_num tariffsDefault_discount">
                  -${{ currentTariff?.discount }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="currentTariff?.trialDays"
            class="purchase__info-item tariffsDefault_discount-block"
          >
            <div class="purchase__info-label text text-lg">
              {{ $t('web.prices_trial') }}
            </div>
            <div class="purchase__info-data text text-lg">
              <div>
                <span class="purchase__info-label_num tariffsDefault_discount">
                  {{ $t('web.days', { count: currentTariff?.trialDays }) }}
                </span>
              </div>
            </div>
          </div>
          <div class="purchase__info-item purchase__info-item_main">
            <div class="purchase__info-label text text-xl">
              {{ $t('web.prices_order-total') }}:
            </div>
            <div class="purchase__info-data text text-xl">
              <div>
                <span class="purchase__info-label_num tariffsDefault_sum">
                  ${{ currentTariff?.price }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchase__input">
        <div class="col-12 pr-md-0 p-0">
          <div class="form purchase__input-form">
            <div class="d-flex justify-content-between">
              <div class="purchase__input-title text text-lg p-0 d-flex">
                {{ $t('web.prices_email') }}
              </div>
              <form @submit.prevent="onButtonClick">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.EMAIL"
                  v-model="cEmail"
                  autocomplete="on"
                  class="p-0"
                  @focus="onFocus"
                  @blur="onBlur"
                  :type="CONSTANTS.INPUT.META.TYPE.EMAIL"
                  :view="CONSTANTS.INPUT.META.VIEW.INLINE"
                  :validateMethods="validateMethods[CONSTANTS.LOCAL.REFS.EMAIL]"
                ></c-input>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chameleon-purchase-actions" ref="chameleonPurchaseActions">
      <div class="chameleon-purchase__button-wrapper">
        <div class="chameleon-purchase__button" @click="onButtonClick">
          <div v-if="!loading">Pay</div>
          <div v-else class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="chameleon-purchase__policy text-center">
        <p class="text text-xs" data-typograf>
          By continuing you accept our<br />
          <c-link
            :to="{ name: $ROUTER.NAME.TERMS }"
            blank
            nowrap
            bold
            underline
            label="Terms of Use"
            color="#ff604f"
          />
          and
          <c-link
            :to="{ name: $ROUTER.NAME.PRIVACY_POLICY }"
            color="#ff604f"
            blank
            nowrap
            bold
            underline
            label="Privacy Policy"
          />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import * as CONSTANTS from '@/CONSTANTS'
import CInput from '@/components/Input'
import METHODS from '@/utils/Validator/METHODS'
import CLink from '@/components/Link'
import { loadStripe } from '@stripe/stripe-js/pure'
const CryptoJS = require('crypto-js')
import * as Sentry from '@sentry/browser'
import gtag from '@/utils/gtag'

const _CONSTANTS = {
  REFS: {
    EMAIL: 'email',
  },
}
export default {
  name: 'Purchase',
  components: {
    CInput,
    CLink,
  },
  data() {
    this.CONSTANTS = Object.assign({}, CONSTANTS, { LOCAL: _CONSTANTS })
    this.stripePublishableKey = process.env.VUE_APP__STRIPE_PUBLIC_KEY
    return {
      cEmail: '',
      stripeSessionId: null,
      stripe: null,
      loading: false,
      paymentImages: [
        {
          name: 'Mastercard',
          src: require('@/assets/image/paymentMethods/mastercard.png'),
        },
        {
          name: 'VISA',
          src: require('@/assets/image/paymentMethods/visa.png'),
        },
      ],
    }
  },
  computed: {
    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.EMAIL]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.email')],
            METHODS.IS_EMAIL,
          ],
        }
      },
    },
    formButtonInsteadOfSubmit: {
      get() {
        return async () => {
          this.stripe.redirectToCheckout({
            sessionId: this.stripeSessionId,
          })
        }
      },
    },
  },
  mounted() {
    this.initStripe()
    this.setHeight()
  },
  props: {
    currentTariff: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async initStripe() {
      if (this.stripePublishableKey && this.stripePublishableKey !== 'NULL') {
        this.stripe = await loadStripe(this.stripePublishableKey)
      }
    },
    setHeight(height = window.innerHeight) {
      if (this.$refs.onboardingContent && !this.toShowPurchase) {
        this.$refs.onboardingContent.style.maxHeight = `${height}px`
      }
    },
    onButtonClick() {
      this.loading = true
      this.formButtonBeforeSubmit()
        .then(() => {
          this.formButtonInsteadOfSubmit()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    formButtonBeforeSubmit() {
      return new Promise((resolve, reject) => {
        const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
          if (this.$refs[type]) {
            const ref = this.$refs[type]
            if (Array.isArray(ref)) {
              acc.push(...ref)
            } else {
              acc.push(ref)
            }
          }
          return acc
        }, [])
        const promises = inputs.map((input) => input.validate())
        Promise.all(promises)
          .then(() => {
            this.$api.merchant[
              this.CONSTANTS.PAYMENT.PAY_API_NAME[
                this.CONSTANTS.PAYMENT.MERCHANT.STRIPE
              ]
            ]({
              tariff_id: this.currentTariff?.id,
              email: this.cEmail,
            })
              .then((res) => {
                this.prepareFormButtonProps(res.data)
                gtag('BuyButtonStep2')
                this.$nextTick(() => {
                  if (res.data.user?.password) {
                    localStorage.setItem(
                      'password',
                      CryptoJS.AES.encrypt(
                        res.data.user.password,
                        this.$store.getters['config/pwdSecret']
                      ).toString()
                    )
                  }
                  if (res.data.user?.token) {
                    localStorage.setItem('token', res.data.user.token)
                  }
                  resolve()
                })
              })
              .catch((error) => {
                Sentry.captureMessage(
                  JSON.stringify({
                    error: 'errorWhenTryToGetMerchantParams',
                    email: this.cEmail,
                    message:
                      error.errors.data?.[0] ||
                      error.errors[_CONSTANTS.REFS.EMAIL]?.[0],
                  })
                )
                // Ужас. Нужно чтобы таких ифов не было
                if (error.errors.data) {
                  this.$store.dispatch('app/showDialog', {
                    title: error.title,
                    type: 'error',
                    description: error.errors.data[0],
                  })
                } else {
                  const componentsMap = {
                    [_CONSTANTS.REFS.EMAIL]: this.$refs[_CONSTANTS.REFS.EMAIL],
                  }
                  Object.entries(error.errors).forEach((err) => {
                    const ref = componentsMap[err[0]]
                    if (Array.isArray(ref)) {
                      ref.forEach((ref) => {
                        ref.setError(err[1][0])
                      })
                    } else {
                      ref.setError(err[1][0])
                    }
                  })
                }
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      })
    },
    prepareFormButtonProps(data) {
      this.stripeSessionId = data.session.id
    },
  },
}
</script>
<style lang="scss">
.c-input {
  flex-grow: 1;
  margin-left: 16px;
  input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 8px 40px 2px 2px !important;
  }
}
.purchase__input-title {
  margin-bottom: 0 !important;
  align-items: flex-end;
}
</style>
<style scoped lang="scss">
@import 'index';
.onboarding {
  &__content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &--purchase {
      padding: 24px;
      justify-content: space-between;
      overflow-y: auto;
    }
  }
}
.chameleon-purchase {
  &__policy {
    color: #959494;
    margin-bottom: 24px;
  }
  &__button {
    width: 100%;
    color: white;
    background: linear-gradient(270deg, #000000 2.17%, #252525 100%);
    border-radius: 20px;
    padding: 12px 16px;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    height: 100%;
    &-wrapper {
      height: 57px;
      margin: 24px 0;
    }
  }
  &-title {
    font-size: 40px;
    line-height: 48px;
    color: #272727;
    font-weight: 700;
    text-align: center;
    margin: 32px 0 64px;
    @media (max-width: 339.98px) {
      font-size: 32px;
      line-height: 38px;
      margin: 24px 0 48px;
    }
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 67px;
  height: 100%;
}
.lds-ellipsis div {
  position: absolute;
  top: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffffffa0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  animation: lds-ellipsis1 0.6s infinite;
  body[dir='rtl'] & {
    right: 8px;
  }
  body[dir='ltr'] & {
    left: 8px;
  }
}
.lds-ellipsis div:nth-child(2) {
  animation: lds-ellipsis2 0.6s infinite;
  body[dir='rtl'] & {
    right: 8px;
  }
  body[dir='ltr'] & {
    left: 8px;
  }
}
.lds-ellipsis div:nth-child(3) {
  animation: lds-ellipsis2 0.6s infinite;
  body[dir='rtl'] & {
    right: 32px;
  }
  body[dir='ltr'] & {
    left: 32px;
  }
}
.lds-ellipsis div:nth-child(4) {
  animation: lds-ellipsis3 0.6s infinite;
  body[dir='rtl'] & {
    right: 56px;
  }
  body[dir='ltr'] & {
    left: 56px;
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0) translate(0, -18px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0, -50%);
    opacity: 1;
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1) translate(0, -50%);
    opacity: 1;
  }
  100% {
    transform: scale(0) translate(0, -18px);
    opacity: 0;
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(24px, -50%);
  }
}
</style>
