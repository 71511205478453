export const CONSTANTS = {
  SCREEN: {
    TRY_NOW: 'PAYWALL_SCREEN',
  },
  VALUE: {
    EMAIL: 'EMAIL',
  },
  TYPE: {
    QUESTION: 'question',
    ANSWER: 'answer',
  },
  ANSWER_TYPE: {
    SELECT: 'ANSWER_TYPE_SELECT',
    BUTTON: 'ANSWER_TYPE_BUTTON',
    INPUT: 'ANSWER_TYPE_INPUT',
  },
  RELATION: {
    ONE_TO_MANY: 'RELATION_ONE_TO_MANY',
    NONE: 'RELATION_NONE',
    MANY_TO_MANY: 'RELATION_MANY_TO_MANY',
  },
}

export default [
  {
    id: 0,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Hello, I’m the VPN99 Chameleon',
    next: -1,
  },
  {
    id: 1,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Hi there!',
        next: -1,
      },
      {
        content: 'Why is a chameleon talking to me?!',
        next: -1,
      },
    ],
  },
  {
    id: 2,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'I’m here to encrypt your data, mask your IP-address and block malicious sites 😎',
    next: -1,
  },
  {
    id: 3,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Ehm, this is too complicated 🙄',
        next: -1,
      },
      {
        content: 'It’s quite possible I need it. Tell me more.',
        next: -1,
      },
    ],
  },
  {
    id: 4,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'It’s quite simple',
    next: -1,
  },
  {
    id: 5,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Your VPN is like an anonymous middleman who’s browsing for you.',
    next: -1,
  },
  {
    id: 6,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Kinda surf camouflage. Look at me!',
    next: -1,
  },
  {
    id: 7,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Cool! I’m here for that!',
        next: -1,
      },
      {
        content: 'Nice, but ehm.. I don’t think I need that.',
        next: -1,
      },
    ],
  },
  {
    id: 8,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'Let’s see if you know all the benefits that a VPN service gives you.',
    next: -1,
  },
  {
    id: 9,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Check what you think is fair:',
    next: -1,
  },
  {
    id: 10,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.MANY_TO_MANY,
    submitButton: {
      value: 'OK',
    },
    next: -1,
    variations: [
      {
        content: 'Get full access to the streaming services',
      },
      {
        content: 'Find cheaper flights and bookings',
      },
      {
        content: 'Block ads and malware',
      },
      {
        content: 'Keep searches private',
      },
      {
        content: 'Get a high-speed connection',
      },
      {
        content: 'Hide your actual location',
      },
      {
        content: 'Browse privately',
      },
      {
        content: 'Avoid annoying network restrictions',
      },
    ],
  },
  {
    id: 11,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'All of them!!',
    next: -1,
  },
  {
    id: 12,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'With a VPN you get more content, spend less money, protect any of your data and stay super safe and invisible 😍',
    next: -1,
  },
  {
    id: 13,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Why do you think my data is vulnerable?',
        next: -1,
      },
      {
        content: 'Yeah, these are really cool things about VPN! What else?',
        next: -1,
      },
    ],
  },
  {
    id: 14,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Tell me how often you use public Wi-Fi?',
    next: -1,
  },
  {
    id: 15,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'In cafes, subway, hotels, airports, shopping malls etc',
    next: -1,
  },
  {
    id: 16,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Nonstop',
        next: -1,
      },
      {
        content: 'Quiet often',
        next: -1,
      },
      {
        content: 'Sometimes',
        next: -1,
      },
      {
        content: 'Rarely',
        next: -1,
      },
    ],
  },
  {
    id: 17,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'You do know, right, that even the basic password that they give you in a coffee shop or hotel, doesn’t actually protect you?',
    next: -1,
  },
  {
    id: 18,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'So it’s quite easy for a hacker to snoop on your personal data, passwords, credit cards, search results etc.',
    next: -1,
  },
  {
    id: 19,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content:
          'Oh gosh. Even when I use the internet from a hotspot shared by my friend’s phone?',
        next: -1,
      },
    ],
  },
  {
    id: 20,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Yep, even then.',
    next: -1,
  },
  {
    id: 21,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'But at least at home am I safe with my private WI-Fi?',
        next: -1,
      },
    ],
  },
  {
    id: 22,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'Doing your regular internet things at home using a VPN is also a good idea.',
    next: -1,
  },
  {
    id: 23,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'It’ll keep you from leaving footprints on the web for your provider to scoop up.',
    next: -1,
  },
  {
    id: 24,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Great, cause I’m looking for some privacy!',
        next: -1,
      },
      {
        content: 'My provider spies on me?!',
        next: -1,
      },
    ],
  },
  {
    id: 25,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'That’s right: even if you don’t think you’re being tracked online, you are!',
    next: -1,
  },
  {
    id: 26,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Told you: camouflage rules 😎',
    next: -1,
  },
  {
    id: 27,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.ONE_TO_MANY,
    variations: [
      {
        content: 'Ok, but what if I have different devices?',
        next: -1,
      },
      {
        content: 'Seems like VPN is a cool thing, eh?',
        next: -1,
      },
    ],
  },
  {
    id: 28,
    type: CONSTANTS.TYPE.QUESTION,
    content:
      'VPN99 is friendly with any device you ' +
      'own. It also has special Chrome and ' +
      'Firefox add-ons and more 🙃',
    next: -1,
  },
  {
    id: 29,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'What do you use?',
    next: -1,
  },
  {
    id: 30,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.SELECT,
    relation: CONSTANTS.RELATION.MANY_TO_MANY,
    submitButton: {
      value: 'OK',
    },
    next: -1,
    variations: [
      {
        content: 'MacOS',
      },
      {
        content: 'Windows',
      },
      {
        content: 'Linux',
      },
      {
        content: 'Android',
      },
      {
        content: 'iOS',
      },
    ],
  },
  {
    id: 31,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Great, VPN99 is right for you!',
    next: -1,
  },
  {
    id: 32,
    type: CONSTANTS.TYPE.QUESTION,
    content: 'Let’s begin then?',
    toWriteQuickly: true,
    next: 36,
  },
  // {
  //   id: 32,
  //   type: CONSTANTS.TYPE.QUESTION,
  //   content: 'Let’s begin then? Tell me a little about yourself',
  //   next: -1,
  // },
  // {
  //   id: 33,
  //   type: CONSTANTS.TYPE.ANSWER,
  //   answerType: CONSTANTS.ANSWER_TYPE.INPUT,
  //   relation: CONSTANTS.RELATION.NONE,
  //   submitButton: {
  //     value: 'OK',
  //   },
  //   variations: [
  //     {
  //       placeholder: 'E-mail',
  //       type: 'email',
  //       value: CONSTANTS.VALUE.EMAIL,
  //     },
  //   ],
  //   next: -1,
  // },
  // {
  //   id: 34,
  //   type: CONSTANTS.TYPE.QUESTION,
  //   content: 'Nice to meet you 🤗',
  //   toWriteQuickly: true,
  //   next: -1,
  // },
  // {
  //   id: 35,
  //   type: CONSTANTS.TYPE.QUESTION,
  //   content: 'We’re ready to start now!',
  //   toWriteQuickly: true,
  //   next: -1,
  // },
  {
    id: 36,
    type: CONSTANTS.TYPE.ANSWER,
    answerType: CONSTANTS.ANSWER_TYPE.BUTTON,
    relation: CONSTANTS.RELATION.NONE,
    variations: [
      {
        content: 'Try now',
        goTo: CONSTANTS.SCREEN.TRY_NOW,
        next: null,
      },
    ],
  },
]
