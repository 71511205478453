<template>
  <section
    v-if="!toShowPurchase"
    class="onboarding__content onboarding__content--plans"
    ref="onboardingContent"
  >
    <div>
      <div class="chameleon-plans-main-img__wrapper">
        <img
          class="chameleon-plans-main-img"
          :src="require('./images/chameleonMain.png')"
          alt="Chameleon"
        />
        <img
          v-show="selectedTariff?.period === 'month'"
          class="chameleon-plans-money-back"
          :src="require('./images/moneyBack7.png')"
          alt="Money back"
        />
        <img
          v-show="selectedTariff?.period !== 'month'"
          class="chameleon-plans-money-back"
          :src="require('./images/moneyBack30.png')"
          alt="Money back"
        />
      </div>
      <div class="chameleon-plans-discount__wrapper">
        <div class="chameleon-plans-discount">
          <p class="chameleon-plans-discount__value">
            {{ discountPercent }}% OFF
          </p>
          <p class="chameleon-plans-discount__description">
            Choose the biggest saving!
          </p>
        </div>
      </div>
      <div class="chameleon-plans-tariff-list__wrapper">
        <div class="chameleon-plans-tariff-list">
          <div
            class="chameleon-plans-tariff__wrapper"
            v-for="(tariff, index) in tariffs"
            :key="index"
          >
            <div
              @click="() => selectTariff(tariff)"
              class="chameleon-plans-tariff"
              :class="{
                'chameleon-plans-tariff--selected':
                  tariff.id === selectedTariff?.id,
              }"
            >
              <div class="flex">
                <div class="chameleon-plans-tariff-checkbox__wrapper">
                  <div
                    class="chameleon-plans-tariff-checkbox"
                    v-if="tariff.id === selectedTariff?.id"
                  >
                    <icon
                      class="chameleon-plans-tariff-checkbox__icon"
                      :name="$t('web.icon_check-circle')"
                      :resize="false"
                      width="36"
                      height="36"
                    >
                      <checkmark />
                    </icon>
                  </div>
                  <div
                    class="
                      chameleon-plans-tariff-checkbox
                      chameleon-plans-tariff-checkbox--empty
                    "
                    v-else
                  >
                    <div class="chameleon-plans-tariff-checkbox__icon"></div>
                  </div>
                </div>
                <div class="chameleon-plans-tariff-info__wrapper">
                  <div class="chameleon-plans-tariff-info">
                    <div class="chameleon-plans-tariff-info__title">
                      {{ tariff.title }}
                    </div>
                    <div class="chameleon-plans-tariff-info-full-price">
                      <span
                        v-if="tariff.price !== tariff.oldPrice"
                        class="
                          chameleon-plans-tariff-info-full-price__old-price
                          text-line-through
                        "
                      >
                        ${{ tariff.oldPrice.toFixed(2) }}
                      </span>
                      <span
                        class="
                          chameleon-plans-tariff-info-full-price__new-price
                        "
                      >
                        ${{ tariff.price.toFixed(2) }}
                      </span>
                    </div>
                    <div
                      class="chameleon-plans-tariff-info__description"
                      v-if="
                        tariff.period.toLowerCase() === 'year' &&
                        tariff.numberOfPeriods === 1
                      "
                    >
                      billed every year
                    </div>
                  </div>
                </div>
              </div>
              <div class="chameleon-plans-tariff-price__wrapper">
                <div class="chameleon-plans-tariff-price">
                  <div
                    v-if="tariff.discountPercent"
                    class="chameleon-plans-tariff-price-discount__wrapper"
                  >
                    <div class="chameleon-plans-tariff-price-discount">
                      SAVE {{ tariff.discountPercent }}%
                    </div>
                  </div>
                  <div class="chameleon-plans-tariff-price__price-per-month">
                    ${{ tariff.pricePerMonth.toFixed(2) }}
                  </div>
                  <div class="chameleon-plans-tariff-price__description">
                    per month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="chameleon-plans__button-wrapper">
        <div class="chameleon-plans__button" @click="onButtonClick">
          Continue
        </div>
      </div>
      <div class="chameleon-plans__policy text-center">
        <p class="text text-xs" data-typograf>
          By continuing you accept our<br />
          <c-link
            :to="{ name: $ROUTER.NAME.TERMS }"
            blank
            nowrap
            bold
            underline
            label="Terms of Use"
            color="#ff604f"
          />
          and
          <c-link
            :to="{ name: $ROUTER.NAME.PRIVACY_POLICY }"
            color="#ff604f"
            blank
            nowrap
            bold
            underline
            label="Privacy Policy"
          />
        </p>
      </div>
    </div>
  </section>
  <purchase v-if="toShowPurchase" :currentTariff="selectedTariff"></purchase>
</template>

<script>
import Icon from '@/components/Icon'
import Checkmark from './images/checkmark-circle.svg'
import CLink from '@/components/Link'
import Purchase from './Purchase'
import gtag from '@/utils/gtag'

export default {
  name: 'Plans',
  components: {
    Icon,
    Checkmark,
    CLink,
    Purchase,
  },
  data() {
    return {
      selectedTariff: null,
      toShowPurchase: false,
    }
  },
  mounted() {
    this.setHeight()
    this.selectedTariff = this.tariffs[this.tariffs.length - 1]
  },
  watch: {
    '$store.state.app.windowHeight': function (height) {
      this.setHeight(height)
    },
  },
  computed: {
    tariffs: {
      get() {
        return this.$store.getters['paywall/paywallTariffs'].sortBy.period()
      },
    },
    discountPercent: {
      get() {
        let maxDiscountPercent = 0
        this.tariffs?.forEach((tariff) => {
          maxDiscountPercent = Math.max(
            tariff.discountPercent,
            maxDiscountPercent
          )
        })
        return maxDiscountPercent
      },
    },
  },
  methods: {
    selectTariff(tariff) {
      this.selectedTariff = tariff
      gtag('BuyButtonStep1')
    },
    setHeight(height = window.innerHeight) {
      if (this.$refs.onboardingContent && !this.toShowPurchase) {
        this.$refs.onboardingContent.style.maxHeight = `${height}px`
      }
    },
    onButtonClick() {
      this.toShowPurchase = true
      gtag(`onboardingChameleon_goTo_PURCHASE_SCREEN`)
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.onboarding {
  &__content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    &--plans {
      justify-content: space-between;
    }
  }
}
.chameleon-plans {
  &-money-back {
    position: absolute;
    top: 36px;
    right: 32px;
    width: 82px;
    @media (max-height: 567.98px) {
      width: 68px;
    }
  }
  &__policy {
    color: #959494;
    margin-bottom: 24px;
  }
  &__button {
    width: 100%;
    color: white;
    background: linear-gradient(270deg, #000000 2.17%, #252525 100%);
    border-radius: 20px;
    padding: 12px 16px;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    margin: 24px 0;
    @media (max-height: 567.98px) {
      margin: 12px 0;
    }
    &-wrapper {
      margin: 0 12px;
    }
  }
  &-main-img {
    &__wrapper {
      position: relative;
      margin: 24px -6px 0;
      @media (max-height: 686.98px) and (min-height: 567.99px) {
        margin: 0 -6px;
      }
      @media (max-height: 567.98px) {
        margin: 0 24px;
      }
    }
  }
  &-discount {
    text-align: center;
    &__wrapper {
      margin: 12px;
      @media (max-height: 686.98px) and (min-height: 567.99px) {
        margin: 0 12px;
      }
      @media (max-height: 567.98px) {
        margin: 0 12px;
      }
    }
    &__value {
      color: #ff604f;
      font-size: 58px;
      line-height: 69px;
      font-weight: 900;
      @media (max-height: 686.98px) and (min-height: 567.99px) {
        font-size: 36px;
        line-height: 42px;
      }
      @media (max-height: 567.98px) {
        font-size: 36px;
        line-height: 42px;
      }
    }
    &__description {
      color: #282828;
      font-size: 24px;
      font-weight: 700;
      @media (max-height: 686.98px) and (min-height: 567.99px) {
        font-size: 20px;
      }
      @media (max-height: 567.98px) {
        font-size: 20px;
      }
    }
  }
  &-tariff {
    border-radius: 20px;
    background: #fdf4ea;
    padding: 27px 32px 27px 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ff604f00;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    @media (max-height: 686.98px) and (min-height: 567.99px) {
      padding: 18px 32px 18px 20px;
    }
    @media (max-height: 567.98px) {
      padding: 18px 32px 18px 20px;
    }
    &--selected {
      border: 1px solid #ff604f;
    }
    &__wrapper {
      margin: 24px 12px;
      @media (max-height: 686.98px) and (min-height: 567.99px) {
        margin: 16px 12px;
      }
      @media (max-height: 567.98px) {
        margin: 16px 12px;
      }
      &:first-child {
        margin-top: 12px;
      }
      &:last-child {
        margin-bottom: 12px;
      }
    }
    &-checkbox {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      margin-left: -4px;
      &--empty {
        .chameleon-plans-tariff-checkbox__icon {
          border: 1px solid #282828;
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
      }
      &__wrapper {
        display: flex;
        align-items: center;
      }
    }
    &-price {
      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__description {
        color: #aaa;
      }
      &__price-per-month {
        font-size: 30px;
        color: #3c3c3c;
        line-height: 33px;
        font-weight: 900;
        @media (max-height: 686.98px) and (min-height: 567.99px) {
          font-size: 25px;
        }
        @media (max-height: 567.98px) {
          font-size: 25px;
        }
      }
      &-discount {
        background-color: #fe441a;
        border-radius: 25px;
        color: #3c3c3c;
        font-size: 10px;
        font-weight: 700;
        width: fit-content;
        padding: 6px 12px;
        &__wrapper {
          position: absolute;
          top: 0;
          transform: translateY(-45%);
        }
      }
    }
    &-info {
      &__title {
        color: #3c3c3c;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 2px;
        @media (max-height: 686.98px) and (min-height: 567.99px) {
          font-size: 16px;
        }
        @media (max-height: 567.98px) {
          font-size: 16px;
        }
      }
      &__description {
        color: #3c3c3c;
        font-size: 12px;
        @media (max-height: 686.98px) and (min-height: 567.99px) {
          font-size: 11px;
        }
        @media (max-height: 567.98px) {
          font-size: 11px;
        }
      }
      &-full-price {
        font-size: 18px;
        align-items: center;
        display: flex;
        @media (max-height: 686.98px) and (min-height: 567.99px) {
          font-size: 16px;
        }
        @media (max-height: 567.98px) {
          font-size: 16px;
        }
        &__old-price {
          color: #ff604f;
          margin-right: 4px;
        }
        &__new-price {
          color: #3c3c3c;
        }
      }
    }
  }
}

.text-line-through {
  &::after {
    border-bottom: 2px solid var(--main);
    top: 53%;
    opacity: 1;
  }
}
</style>
