<template>
  <main ref="onboarding" class="onboarding" v-show="!hideOnboarding">
    <section
      v-if="currentScreen === SCREEN_HISTORY"
      class="onboarding__content onboarding__content--history"
      ref="onboardingContent"
    >
      <div class="chameleon-history__wrapper">
        <div
          ref="chameleonHistoryInnerWrapper"
          class="chameleon-history__inner-wrapper"
        >
          <div
            class="chameleon-history"
            ref="chameleonHistory"
            :class="{
              'chameleon-history__padding-for-mini-img': toShowChameleon,
            }"
          >
            <div
              class="chameleon-history__main-img-wrapper"
              :class="{
                'chameleon-history__main-img-wrapper--hidden':
                  toHideMainChameleon,
              }"
            >
              <img
                class="chameleon-history__main-img"
                :src="require('./images/chameleonMain.png')"
                alt="Chameleon"
              />
            </div>
            <transition-group :name="transitionName" tag="div">
              <div
                v-for="(item, index) in history"
                :key="index"
                :class="[
                  `chameleon-history__item chameleon-history__${item.type}`,
                  {
                    'chameleon-history__item--last-in-same': item.lastInSame,
                  },
                ]"
              >
                <div
                  class="chameleon-history__item-content"
                  :class="{
                    'chameleon-history__item-content--angle': item.lastInSame,
                  }"
                >
                  {{ item.content }}
                </div>
                <div
                  v-if="item.lastInSame"
                  class="chameleon-history__item-angle"
                >
                  <answer-angle
                    v-if="item.type === FLOW_CONSTANTS.TYPE.ANSWER"
                  />
                  <question-angle
                    v-else-if="item.type === FLOW_CONSTANTS.TYPE.QUESTION"
                  />
                </div>
              </div>
            </transition-group>
            <div
              class="typing__wrapper"
              :class="{
                'typing__wrapper--margin-left-lg': toShowChameleon,
                'typing__wrapper--hidden': !isTyping,
              }"
            >
              <div class="typing">
                <div class="typing__progress-wrapper">
                  <div class="typing__progress">
                    <div class="progress__dot progress__dot--first"></div>
                    <div class="progress__dot progress__dot--second"></div>
                    <div class="progress__dot progress__dot--third"></div>
                  </div>
                </div>
              </div>
            </div>
            <div ref="scrollAnchor" class="scroll-anchor"></div>
          </div>
        </div>
        <div
          class="chameleon-history__mini-img-wrapper"
          :class="{
            'chameleon-history__mini-img-wrapper--hidden': !toShowChameleon,
          }"
        >
          <chameleon-mini></chameleon-mini>
        </div>
      </div>
      <div
        ref="chameleonActionsWrapper"
        class="chameleon-actions__wrapper"
        style="height: 0"
      >
        <div
          ref="chameleonActionsProgress"
          class="chameleon-actions__progress"
        ></div>
        <div class="chameleon-actions__progress-background"></div>
        <div
          class="chameleon-actions__more"
          :class="{
            'chameleon-actions__more--hidden':
              !toShowMore || answerScrollOnTheTop,
          }"
        >
          <icon
            class="chameleon-actions__more-icon"
            :name="$t('web.icon_up')"
            width="16"
            height="16"
            :resize="false"
            fill
            color="#cdcdcd"
          >
            <up />
          </icon>
        </div>
        <div
          ref="chameleonActionsInnerWrapper"
          class="chameleon-actions__inner-wrapper"
        >
          <div ref="chameleonActions" class="chameleon-actions">
            <div class="chameleon-actions__answers">
              <div
                v-if="
                  currentItem?.answerType === FLOW_CONSTANTS.ANSWER_TYPE.SELECT
                "
              >
                <div
                  class="chameleon-actions__answer"
                  v-for="(answer, index) in currentItem?.variations"
                  :key="index"
                >
                  <div
                    class="chameleon-actions__answer-content"
                    :class="{
                      'chameleon-actions__answer-content--checked':
                        currentAnswers.find(
                          (currentAnswer) => currentAnswer.id === answer.id
                        ),
                    }"
                    @click="() => giveAnswer(index)"
                  >
                    {{ answer.content }}
                  </div>
                </div>
              </div>
              <div
                class="chameleon-actions__inputs"
                v-if="
                  currentItem?.answerType === FLOW_CONSTANTS.ANSWER_TYPE.INPUT
                "
              >
                <div
                  class="chameleon-actions__input-wrapper"
                  v-for="(input, index) in currentItem?.variations"
                  :key="index"
                >
                  <form @submit.prevent="submitAnswer">
                    <input
                      class="chameleon-actions__input"
                      :type="input.type"
                      :placeholder="input.placeholder"
                      :value="historyValues[input.value] || ''"
                      @input="onInput($event.target.value, index)"
                    />
                  </form>
                </div>
              </div>
              <div
                class="chameleon-actions__buttons"
                v-if="
                  currentItem?.answerType === FLOW_CONSTANTS.ANSWER_TYPE.BUTTON
                "
              >
                <div
                  class="chameleon-actions__button-wrapper"
                  v-for="(button, index) in currentItem?.variations"
                  :key="index"
                >
                  <div
                    class="chameleon-actions__button"
                    @click="onButtonClick(index)"
                  >
                    {{ button.content }}
                  </div>
                </div>
              </div>
              <div
                v-if="currentItem?.submitButton?.value"
                class="chameleon-actions__answer"
              >
                <div
                  class="
                    chameleon-actions__answer-content
                    chameleon-actions__answer-content--submit
                  "
                  :class="{
                    'chameleon-actions__answer-content--hidden':
                      isSubmitButtonHidden,
                  }"
                  @click="submitAnswer"
                >
                  {{ currentItem?.submitButton.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <plans v-else-if="currentScreen === FLOW_CONSTANTS.SCREEN.TRY_NOW" />
  </main>
</template>

<script>
import { ENV } from '@/CONSTANTS'
import questionFlow, { CONSTANTS as FLOW_CONSTANTS } from './questionFlow'
import Icon from '@/components/Icon'
import Up from '@/assets/image/icons/svg/up.svg'
import AnswerAngle from './images/vpn99DefaultMessageAngle.svg'
import QuestionAngle from './images/whiteMessageAngle.svg'
import ChameleonMini from './images/chameleonMini.svg'
import Plans from './plans'
import gtag from '@/utils/gtag'

const ANIMATION_TIME = 400
const STORE_KEY = 'ABTest/Onboarding/Chameleon/v1'

export default {
  name: 'Chameleon',
  components: {
    Icon,
    Up,
    AnswerAngle,
    QuestionAngle,
    ChameleonMini,
    Plans,
  },
  data() {
    this.SCREEN_HISTORY = 'HISTORY'
    return {
      isFirstLoad: true, // store
      currentScreen: undefined, // stored
      currentID: -1, // stored
      currentAnswers: [],
      history: [], // stored
      progressCounter: 0, // stored
      progressTotal: null, // stored
      toShowMore: false,
      answerScrollOnTheTop: true,
      isTyping: false,
      historyValues: {},
      get storeData() {
        try {
          return JSON.parse(localStorage.getItem(STORE_KEY) || '{}')
        } catch {
          return {}
        }
      },
    }
  },
  computed: {
    isSubmitButtonHidden: {
      get() {
        return this.currentAnswers.length === 0
      },
    },
    toShowChameleon: {
      get() {
        return this.toHideMainChameleon
      },
    },
    toHideMainChameleon: {
      get() {
        return this.progressCounter >= 2
      },
    },
    FLOW_CONSTANTS: {
      get() {
        return FLOW_CONSTANTS
      },
    },
    transitionName: {
      get() {
        if (!this.history[this.history.length - 1]?.type) {
          return 'transition-question'
        }
        return `transition-${this.history[this.history.length - 1]?.type}`
      },
    },
    currentItem: {
      get() {
        const flowItem = this.questionFlow.find(
          (item) => item.id === this.currentID
        )
        if (!flowItem) {
          return null
        }
        const item = {
          ...flowItem,
        }
        if (item.variations) {
          item.variations = item.variations.map((variation, index) => ({
            ...variation,
            id: index,
          }))
        }
        return item
      },
    },
    hideOnboarding: {
      get() {
        if (ENV.IS_DEV) {
          return (
            !this.$store.getters['app/isMobileSize'] ||
            this.$store.getters['user/token']
          )
        } else {
          return (
            !this.$store.getters['app/isMobile'] ||
            this.$store.getters['user/token']
          )
        }
      },
    },
    questionFlow: {
      get() {
        return questionFlow
      },
    },
  },
  watch: {
    '$store.state.app.windowHeight': function (height) {
      this.setHeight(height)
    },
    progressCounter: {
      handler(progressCounter) {
        if (this.currentScreen !== this.SCREEN_HISTORY) {
          return
        }
        this.setToStore({ progressCounter })
        this.$nextTick(() => {
          this.$refs.chameleonActionsProgress.style.width = `${
            (this.progressCounter / this.progressTotal) * 100 +
            ((this.progressTotal - this.progressCounter) / this.progressTotal) *
              5
          }vw`
        })
      },
    },
    currentID: {
      handler(currentID) {
        if (this.currentScreen !== this.SCREEN_HISTORY) {
          return
        }
        this.setToStore({ currentID })
      },
    },
    isFirstLoad: {
      handler(isFirstLoad) {
        this.setToStore({ isFirstLoad })
      },
    },
    progressTotal: {
      handler(progressTotal) {
        if (this.currentScreen !== this.SCREEN_HISTORY) {
          return
        }
        this.setToStore({ progressTotal })
      },
    },
    currentScreen: {
      handler(currentScreen) {
        this.setToStore({ currentScreen })
        this.$nextTick(() => {
          this.setHeight()
        })
      },
    },
    history: {
      handler(history) {
        if (this.currentScreen !== this.SCREEN_HISTORY) {
          return
        }
        this.setToStore({ history })
      },
      deep: true,
    },
    questionFlow(flow) {
      if (this.currentScreen !== this.SCREEN_HISTORY) {
        return
      }
      this.progressTotal =
        flow.filter((item) => item.type === FLOW_CONSTANTS.TYPE.ANSWER).length -
        1
    },
    hideOnboarding(value) {
      if (value) {
        this.$router.push({ name: this.$ROUTER.NAME.HOME })
      }
    },
    currentItem(item) {
      if (this.currentScreen !== this.SCREEN_HISTORY) {
        return
      }
      this.isTyping = false
      setTimeout(() => {
        this.isTyping = this.currentItem?.type === FLOW_CONSTANTS.TYPE.QUESTION
      }, 100)
      if (item?.type === FLOW_CONSTANTS.TYPE.QUESTION) {
        setTimeout(() => {
          this.next(item.next ?? -1)
        }, Math.max((Math.log(Object.values(item.content).join(' ').length / (item.toWriteQuickly ? 6 : 3) + 1) - 1.2) / 2.5, 0.2) * 1000)
        this.toShowMore = false
      } else if (item?.type === FLOW_CONSTANTS.TYPE.ANSWER) {
        item.variations?.forEach((variation) => {
          if (variation.value) {
            this.historyValues[variation.value] = ''
          }
        })
      }
      this.$nextTick(() => {
        const height = Math.min(
          document.documentElement.clientHeight * 0.7,
          document.documentElement.clientHeight - 300,
          this.$refs.chameleonActions.clientHeight
        )
        setTimeout(() => {
          this.toShowMore =
            this.$refs.chameleonActionsInnerWrapper.clientHeight <
            this.$refs.chameleonActions.clientHeight - 10
          this.answerScrollOnTheTop = !this.toShowMore
        }, ANIMATION_TIME + 50)
        this.$refs.chameleonActionsWrapper.style.height = `${height}px`
        this.$refs.chameleonActionsInnerWrapper.style.height = `${height}px`
      })
    },
  },
  beforeMount() {
    if (this.hideOnboarding) {
      this.$router.push({ name: this.$ROUTER.NAME.HOME })
      return
    }
    this.$store.dispatch('paywall/setPaywall', {
      paywallName: this.$store.state.whitelabel.config?.paywall?.default,
    })
    Object.entries(this.storeData).forEach(([key, value]) => {
      this[key] = value
    })
    if (this.isFirstLoad) {
      gtag(`onboardingChameleon_firstScreenLoaded`)
    }
    if (!this.currentScreen) {
      this.currentScreen = this.SCREEN_HISTORY
    }
    this.isFirstLoad = false
  },
  mounted() {
    this.setHeight()
    if (this.hideOnboarding) {
      return
    }
    if (this.currentScreen !== this.SCREEN_HISTORY) {
      return
    }
    this.progressTotal =
      this.progressTotal ||
      this.questionFlow.filter(
        (item) => item.type === FLOW_CONSTANTS.TYPE.ANSWER
      ).length - 1
    if (!this.currentItem) {
      this.next(-1)
    }
    this.$nextTick(() => {
      this.scrollerListener = () => {
        this.answerScrollOnTheTop =
          this.$refs.chameleonActionsInnerWrapper.clientHeight -
            this.$refs.chameleonActionsInnerWrapper.scrollTop >
          this.$refs.chameleonActions.clientHeight - 10
      }
      this.$refs.chameleonActionsInnerWrapper.addEventListener(
        'scroll',
        this.scrollerListener
      )
      let counter = 0
      document
        .getElementsByClassName('chameleon__black-part')
        .forEach((el, index) => {
          if (index === 0) {
            el.style.animationDelay = `${0.02 * 10}s`
          }
          if (index === 5) {
            el.style.animationDelay = `${0.06 * 10}s`
          }
          if ([0, 5, 7, 16, 17].includes(index)) {
            return
          }
          ++counter
          el.style.animationDelay = `${counter * 0.003 * 10}s`
        })
    })
  },
  beforeUnmount() {
    this.$refs.chameleonActionsInnerWrapper.removeEventListener(
      this.scrollerListener
    )
  },
  methods: {
    setHeight(height = window.innerHeight) {
      if (
        this.$refs.onboarding &&
        this.$refs.onboardingContent &&
        this.currentScreen === this.SCREEN_HISTORY
      ) {
        this.$refs.onboarding.style.maxHeight = `${height}px`
        this.$refs.onboardingContent.style.maxHeight = `${height}px`
      } else if (
        this.$refs.onboarding &&
        this.currentScreen !== this.SCREEN_HISTORY
      ) {
        this.$refs.onboarding.style.maxHeight = null
        if (this.$refs.onboardingContent) {
          this.$refs.onboardingContent.style.maxHeight = null
        }
      }
    },
    setToStore(object) {
      const [[key, value]] = Object.entries(object)
      localStorage.setItem(
        STORE_KEY,
        JSON.stringify({ ...this.storeData, [key]: value })
      )
    },
    onButtonClick(index) {
      switch (this.currentItem?.variations[index].goTo) {
        case FLOW_CONSTANTS.SCREEN.TRY_NOW:
          this.currentScreen = FLOW_CONSTANTS.SCREEN.TRY_NOW
          gtag(`onboardingChameleon_goTo_${FLOW_CONSTANTS.SCREEN.TRY_NOW}`)
          break
      }
    },
    onInput(value, index) {
      this.historyValues[this.currentItem?.variations[index].value] = value
      const currentAnswerIndex = this.currentAnswers.findIndex(
        (currentAnswer) =>
          this.currentItem?.variations[index].id === currentAnswer.id
      )
      if (value) {
        if (~currentAnswerIndex) {
          this.currentAnswers[currentAnswerIndex].content = value
        } else {
          this.currentAnswers.push({
            ...this.currentItem?.variations[index],
            content: value,
          })
        }
      } else if (~currentAnswerIndex) {
        this.currentAnswers.splice(currentAnswerIndex, 1)
      }
    },
    submitAnswer() {
      if (this.currentAnswers.length === 0) {
        return
      }
      this.next(this.currentItem?.next || this.currentAnswers[0].next)
      gtag(
        `onboardingChameleon_step_${this.progressCounter < 10 ? '0' : ''}${
          this.progressCounter
        }_of_${this.progressTotal}`
      )
    },
    giveAnswer(index) {
      const answer = this.currentItem?.variations[index]
      const currentIndex = this.currentAnswers.findIndex(
        (currentAnswer) => answer.id === currentAnswer.id
      )
      if (~currentIndex) {
        this.currentAnswers.splice(currentIndex, 1)
        return
      }
      this.currentAnswers.push(answer)
      switch (this.currentItem?.relation) {
        case FLOW_CONSTANTS.RELATION.ONE_TO_MANY:
          this.submitAnswer()
          break
        case FLOW_CONSTANTS.RELATION.MANY_TO_MANY:
          break
      }
    },
    next(id) {
      if (this.currentItem?.type === FLOW_CONSTANTS.TYPE.QUESTION) {
        if (
          this.history[this.history.length - 1]?.type ===
          FLOW_CONSTANTS.TYPE.QUESTION
        ) {
          this.history.splice(this.history.length - 1, 1, {
            ...this.history[this.history.length - 1],
            lastInSame: false,
          })
        }
        this.history.push({ ...this.currentItem, lastInSame: true })
      }
      if (this.currentItem?.type === FLOW_CONSTANTS.TYPE.ANSWER) {
        const answers = []
        this.currentAnswers.forEach((answer) => {
          answers.push(
            Object.assign(answer, {
              id: this.currentItem?.id,
              type: this.currentItem?.type,
            })
          )
        })
        this.currentAnswers = []
        this.history.push(
          ...answers.map((answer, index) => ({
            ...answer,
            lastInSame: index === answers.length - 1,
          }))
        )
        this.progressCounter++
      }
      this.$refs.chameleonHistoryInnerWrapper.scrollTo(
        0,
        this.$refs.chameleonHistoryInnerWrapper.offsetTop + 1
      )
      this.$nextTick(() => {
        this.$refs.chameleonHistoryInnerWrapper.scrollTo(
          0,
          this.$refs.chameleonHistoryInnerWrapper.offsetTop + 1
        )
        this.testVar = Math.abs(~-(this.testVar ?? 1))
        this.$refs.chameleonActionsWrapper.style.height = `${
          141 + this.testVar
        }px`
        this.$refs.chameleonActionsInnerWrapper.style.height = `${
          141 + this.testVar
        }px`
        this.$refs.chameleonActions.style.height = `${141 + this.testVar}px`
        this.$nextTick(() => {
          this.$refs.chameleonHistoryInnerWrapper.scrollTo(
            0,
            this.$refs.chameleonHistoryInnerWrapper.offsetTop + 1
          )
          this.$refs.chameleonActions.style.height = null
          setTimeout(() => {
            const height = Math.min(
              document.documentElement.clientHeight * 0.7,
              document.documentElement.clientHeight - 300,
              this.$refs.chameleonActions.clientHeight
            )
            this.$refs.chameleonActionsWrapper.style.height = `${height}px`
            this.$refs.chameleonActionsInnerWrapper.style.height = `${height}px`
            setTimeout(() => {
              this.toShowMore =
                this.$refs.chameleonActionsInnerWrapper.clientHeight <
                this.$refs.chameleonActions.clientHeight - 10
              this.answerScrollOnTheTop = !this.toShowMore
            }, ANIMATION_TIME + 50)
          }, 200)
        })
      })
      this.currentID = id === -1 ? this.currentID + 1 : id
    },
  },
}
</script>

<style lang="scss">
.chameleon {
  &__black-part {
    fill: #7bb943;
    animation: change-chameleon-colors 10s infinite ease-in-out;
  }
  &__cheek {
    filter: brightness(0.8) opacity(0.7);
    fill: #7bb943;
    animation: change-chameleon-colors 10s infinite ease-in-out;
    animation-delay: 0.15 * 10s;
  }
}

@keyframes change-chameleon-colors {
  0% {
    fill: #7bb943;
    timing-function: ease-in-out;
  }
  15% {
    fill: #4769b1;
    timing-function: ease-in-out;
  }
  30% {
    fill: #aa4d9d;
    timing-function: ease-in-out;
  }
  45% {
    fill: #e9212c;
    timing-function: ease-in-out;
  }
  60% {
    fill: #ed7921;
    timing-function: ease-in-out;
  }
  84% {
    fill: #f4b820;
    timing-function: ease-in-out;
  }
  100% {
    fill: #7bb943;
    timing-function: ease-in-out;
  }
}
</style>

<style lang="scss" scoped>
.onboarding {
  &__content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    &--history {
      justify-content: space-between;
    }
  }
}

.chameleon-history {
  &__padding-for-mini-img {
    padding-bottom: 140px;
  }
  &__mini-img-wrapper {
    position: absolute;
    bottom: -16px;
    left: -80px;
    width: 200px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s,
      left 0.6s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s,
      bottom 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
    &--hidden {
      opacity: 0;
      left: -100px;
      bottom: -150px;
      transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1),
        left 0.6s cubic-bezier(0.25, 0.1, 0.25, 1),
        bottom 0.4s cubic-bezier(0.25, 0.1, 0.25, 1) 0.6s;
    }
  }
  &__main-img {
    &-wrapper {
      margin: 24px -6px;
      transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
        margin 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 1;
      pointer-events: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &--hidden {
        opacity: 0;
        margin: 0px -6px -120px;
      }
    }
  }
  &__wrapper {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
  }
  &__inner-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }
  &__item {
    margin: 14px 12px;
    display: flex;
    flex-direction: column;
    &--last-in-same {
      margin-bottom: 25px;
    }
    &-content {
      padding: 6px 12px;
      border-radius: 12px;
      width: fit-content;
      max-width: 90%;
      transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
    &-angle {
      width: 18px;
      display: flex;
      justify-content: flex-start;
      margin-top: -1px;
    }
  }
  &__question {
    align-items: flex-start;
    .chameleon-history__item-content {
      background-color: white;
      border: 1px solid #cdcdcd;
    }
    .chameleon-history__item-content--angle {
      border-bottom-left-radius: 0;
    }
    .chameleon-history__item-angle {
      transform: scaleX(-1);
      align-items: flex-start;
    }
  }
  &__answer {
    align-items: flex-end;
    .chameleon-history__item-content {
      background-color: #ff604f;
      color: white;
    }
    .chameleon-history__item-content--angle {
      border-bottom-right-radius: 0;
    }
    .chameleon-history__item-angle {
      align-items: flex-end;
    }
  }
}
.chameleon-actions {
  &__buttons {
    margin: 24px 0;
  }
  &__button {
    width: 100%;
    color: white;
    background: linear-gradient(270deg, #000000 2.17%, #252525 100%);
    border-radius: 20px;
    padding: 12px 16px;
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    &-wrapper {
      margin: 0 12px;
    }
  }
  &__inputs {
    margin: 24px 0;
  }
  &__input {
    padding: 12px 26px 12px 18px;
    bottom: 0;
    height: auto;
    line-height: 1.25;
    box-shadow: none;
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    &-wrapper {
      margin: 5px 12px;
    }
  }
  &__progress {
    background-color: #ff604f;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    height: 2px;
    width: 5vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &__progress-background {
    background-color: #fff;
    height: 2px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
  }
  &__more {
    background: #ffffff00;
    background: linear-gradient(
      180deg,
      #e7e7e7 5%,
      #ffffffbb 5.1%,
      #ffffff99 50%,
      #f4f4f400 100%
    );
    height: 32px;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    pointer-events: none;
    &--hidden {
      opacity: 0;
    }
  }
  &__inner-wrapper {
    overflow-y: auto;
    min-height: 140px;
    background-color: #e7e7e7;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    display: flex;
    flex-direction: column-reverse;
  }
  &__wrapper {
    position: relative;
    height: fit-content;
    transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  &__answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 0 28px;
    height: 100%;
    min-height: 140px;
    overflow-y: auto;
  }
  &__answer {
    margin: 5px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__answer-content {
    padding: 8px 14px;
    border-radius: 14px;
    width: fit-content;
    border: 1px solid #cdcdcd;
    background-color: #fff;
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    &--hidden {
      background-color: #b6b6b6 !important;
    }
    &--submit {
      background-color: #ff604f;
      color: white;
      margin-top: 8px;
      padding: 10px 24px;
    }
    &--checked {
      background-color: #757575;
      color: white;
    }
  }
}
.onboarding {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.transition-question-enter-active,
.transition-question-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
}
.transition-question-enter-from,
.transition-question-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.transition-answer-enter-active,
.transition-answer-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.transition-answer-enter-from,
.transition-answer-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.typing {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__wrapper {
    position: relative;
    width: 60px;
    margin: 14px 16px;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    &--hidden {
      transition: opacity 0s cubic-bezier(0.25, 0.1, 0.25, 1);
      opacity: 0;
    }
    &--margin-left-lg {
      margin-left: 72px;
    }
  }

  &__progress-wrapper {
    position: relative;
    padding-bottom: 24px;
    width: 100%;
  }

  &__progress {
    position: absolute;
    height: 24px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .progress {
      &__dot {
        transform: scale(0, 0);
        border-radius: 50%;
        background-color: var(--main);
        animation: progress-dot 0.8s infinite ease-in-out;
        &--first {
          animation-delay: 0s;
          height: 7px;
          width: 7px;
        }
        &--second {
          animation-delay: 0.15s;
          height: 10px;
          width: 10px;
        }
        &--third {
          animation-delay: 0.3s;
          height: 7px;
          width: 7px;
        }
      }
    }
  }

  @keyframes progress-dot {
    0% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    45% {
      transform: scale(1, 1);
      timing-function: ease-in-out;
    }
    90% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
    100% {
      transform: scale(0, 0);
      timing-function: ease-in-out;
    }
  }
}
</style>
